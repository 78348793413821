import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { getMobileOperatingSystem } from '../../utils/common';
import { DERIVED_ASSETS_URL_FACTORY } from '../../../constants';
import * as classes from './style.module.scss';

const OS = getMobileOperatingSystem();
const ARIconUrl = new URL('/assets/images/aricon.svg', import.meta.url);

// Helper components (don't need their own modules since they're super simple)
const Thumbnail = ({ asset }) => {
    const filename = `${asset.public_id.split('/').pop()}_Thumbnail`;
    const thumbnailUrl = _.get(DERIVED_ASSETS_URL_FACTORY.modelThumbGif(asset), 'secure_url', '');

    switch (OS) {
        case 'IOS':
            return <img className={classes.thumbnail} src={thumbnailUrl} alt={filename}/>;
        default:
            return (
                <div className={classes.thumbnailContainer}>
                    <img className={classes.thumbnail} src={thumbnailUrl} alt={filename}/>
                    <img className={classes.ARIcon} src={ARIconUrl} alt=''/>
                </div>
            );
    }
};       
const USDZ = ({ asset, children }) => <a rel="ar" href={_.get(DERIVED_ASSETS_URL_FACTORY.usdz(asset), 'secure_url', '')}>{children}</a>;
const GLB = ({ asset, children }) => 
    <a href={`intent://arvr.google.com/scene-viewer/1.0?file=${_.get(DERIVED_ASSETS_URL_FACTORY.glb(asset), 'secure_url', '')}&mode=ar_preferred#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`}>{children}</a>;


// Main component
const ThreeDModel = ({ asset }) => {
    switch (OS) {
        case 'IOS':
            return <>
                <USDZ asset={asset}>
                    <Thumbnail asset={asset}/>
                </USDZ>
                <div>
                    <div className={classes.tipText}>
                    To download, please visit on desktop.
                    </div>
                </div>
            </>;
        case 'ANDROID':
            return <>
                <GLB asset={asset}><Thumbnail asset={asset}/></GLB>
                <div>
                    <div className={classes.tipText}>
                    To download, please visit on desktop.
                    </div>
                </div>
            </>;
        case 'WINDOWS PHONE':
        case 'UNKNOWN':
        default: 
            return (
                <>
                    <Thumbnail asset={asset}/>
                    <div className={classes.downloadLinkDiv}>
                        <div><a className={classes.downloadLink} href={_.get(DERIVED_ASSETS_URL_FACTORY.usdz(asset), 'secure_url', '')}>Download USDZ (iOS)</a></div>
                    </div>
                    <div className={classes.downloadLinkDiv}>
                        <div><a className={classes.downloadLink} href={_.get(DERIVED_ASSETS_URL_FACTORY.glb(asset), 'secure_url', '')}>Download GLB (Android)</a></div>
                    </div>
                </>);
    }
};


Thumbnail.propTypes = {
    asset: PropTypes.object,
    clickable: PropTypes.bool,
};
USDZ.propTypes = {
    asset: PropTypes.object,
    children: PropTypes.node
};
GLB.propTypes = {
    asset: PropTypes.object,
    children: PropTypes.node
};
ThreeDModel.propTypes = {
    asset: PropTypes.object
};

export default ThreeDModel;