import _ from 'lodash';

const CLOUDINARY_3D_TYPES =['bw', 'fbx', 'glb', 'gltf', 'obj', 'ply', 'u3ma','usdz'];
const MOBILE_OS_FLAVORS_WITH_MODEL_SUPPORT = ['IOS', 'ANDROID'];

const DERIVED_ASSETS_URL_FACTORY = {
    image: (asset) => _.get(asset, 'derived', []).find((asset) => asset.format === 'jpg' && asset.secure_url.includes('w_1080')) || _.get(asset, 'derived', []).find((asset) => asset.format === 'jpg'),
    video: (asset) => _.get(asset, 'derived', []).find((asset) => asset.format === 'mp4' && asset.secure_url.includes('w_1080')) || _.get(asset, 'derived', []).find((asset) => asset.format === 'mp4'),
    videoGif: (asset) => _.get(asset, 'derived', []).find((asset) => asset.format === 'gif' && asset.secure_url.includes('w_540')) || _.get(asset, 'derived', []).find((asset) => asset.format === 'gif'),
    modelThumbGif: (asset) => _.get(asset, 'derived', []).find((asset) => asset.format === 'gif' && asset.secure_url.includes('w_350')) || _.get(asset, 'derived', []).find((asset) => asset.format === 'gif'),
    usdz: (asset) => _.get(asset, 'derived', []).find((asset) => asset.format === 'usdz'),
    glb: (asset) => asset.format === 'glb' ? asset :  _.get(asset, 'derived', []).find((asset) => asset.format === 'glb') ,
};

const BILL_API_HOST = process.env.BILL_API_HOST || 'https://api.billcircularity.com/api/';

export {
    BILL_API_HOST,
    CLOUDINARY_3D_TYPES,
    MOBILE_OS_FLAVORS_WITH_MODEL_SUPPORT,
    DERIVED_ASSETS_URL_FACTORY
};