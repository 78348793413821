import React from 'react';

import schedulerLinks from '../../../schedulerLinks.json';
import * as classes from './style.module.scss';

const Scheduler = () => (
    <div className={classes.scheduler}>
        {  
            schedulerLinks.map((link) => 
                <a 
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.scheduleLink}
                    key={`${link.label}_${link.url}`}
                >
                    {link.label}
                </a>)
        }
    </div>
);

Scheduler.proptypes = {
};

export default Scheduler;