import React from 'react';
import PropTypes from 'prop-types';

import * as classes from './style.module.scss';

const Header = ({ type, loaded }) => {
    let headerUrl, swooshUrl, robotUrl;
    
    switch (type) {
        case 'assets':
            headerUrl = new URL('/assets/images/header_blank.svg', import.meta.url);
            swooshUrl = new URL('/assets/images/swooshes.svg', import.meta.url);
            break;
        case 'reserve':
            headerUrl = new URL('/assets/images/header_hello.svg', import.meta.url);
            robotUrl = new URL('/assets/images/header_robot.jpeg', import.meta.url);
            break;
        default:
    }

    return (
        <div className={classes.header}>
            <img className={classes.headerImage} src={headerUrl} alt=''/>
            {swooshUrl && <img className={loaded ? classes.swoosh : classes.spinningSwoosh} src={swooshUrl} alt=''/>}
            {robotUrl && <img className={classes.robotImage} src={robotUrl} alt=''/>}
        </div>
    );
};

Header.propTypes = {
    type: PropTypes.string,
    loaded: PropTypes.bool
};

export default Header;