export const isLocalDevelopment = () => process.env.NODE_ENV !== 'staging' && process.env.NODE_ENV !== 'production';

export const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return 'WINDOWS_PHONE';
    }

    if (/android/i.test(userAgent)) {
        return 'ANDROID';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'IOS';
    }

    return 'UNKNOWN';
};


export const generateLinksTemplate = (startDate = '', numDays = 30) => {
    const days = [];
    for (let i=0; i<numDays; i++) {
        var today = new Date(startDate ? startDate : '');
        var nextDate = new Date(new Date().setDate(today.getDate() + i));

        days.push(nextDate.toLocaleString('en-us', {  weekday: 'long', month: 'short', day: 'numeric' }));
    }

    return JSON.stringify(days.map((day) => ({ label: day, url: '' })) );
};