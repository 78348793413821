import superagent from 'superagent';
import Logger from '@KrashidBuilt/common/utils/logger';
const logger = new Logger(__filename);


const request = async (host, path, options) => {
    const { method = 'get', headers = {}, data = {} } = options;
    
    try {
        switch (method) {
            case 'get':
                return (await superagent.get(`${host}${path}`).set(headers)).body;
            case 'post':
                return (await superagent.post(`${host}${path}`).set(headers).send(data)).body;
            default: 
                throw new Error(`Unsupported request method: ${method}`);
        }
    } catch (error) {
        // DO NOT LOG HEADERS HERE, SINCE HEADERS INCLUDES THE API KEY
        logger.error(`API REQUEST ERROR: ${host, path, error}`);
        throw error;       
    }
};

export {
    request
};