import React from 'react';
import ReactPlayer from 'react-player';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as classes from './style.module.scss';

import { DERIVED_ASSETS_URL_FACTORY } from '../../../constants';

const VideoPlayer = ({ asset, OS }) => <>
    <div
        id={`player_${_.get(asset, 'asset_id')}`}
        className={classes.videoPlayer}
    >
        <ReactPlayer
            url={_.get(DERIVED_ASSETS_URL_FACTORY.video(asset), 'secure_url', '')}
            loop={true}
            playing={true}
            width='100%'
            height='100%'
            // Autoplaying via play only works if muted=true because of chrome policies
            muted={true}
            playsinline
        />
    </div>
    {
        OS === 'IOS' &&                      
        <div className={classes.downloadLinkDiv}>
            <a className={classes.downloadLink} href={_.get(DERIVED_ASSETS_URL_FACTORY.video(asset), 'secure_url', '')}>Download Video</a>
        </div>
    }
</>
;

VideoPlayer.propTypes = {
    asset: PropTypes.object,
    OS: PropTypes.string
};

export default VideoPlayer;