@import '../../../vars.scss';


.thumbnailContainer {
    @include mediaContainer;
    position: relative;

    .ARIcon {
        position: absolute;
        top: 5%; 
        right: 5%;
        width: 5%; 
        background-color: white;
        padding: 2%;
    }
}

.tipText {     
    position: relative;
    padding: 1vh 2vw;
    font-style: italic;
}

.thumbnail {
    width: 100%;
}

