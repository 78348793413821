import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import Logger from '@KrashidBuilt/common/utils/logger';
const logger = new Logger(__filename);

import api from '../../api';
import { Header, ThreeDModel, VideoPlayer } from '../../components';
import { DERIVED_ASSETS_URL_FACTORY, CLOUDINARY_3D_TYPES, MOBILE_OS_FLAVORS_WITH_MODEL_SUPPORT } from '../../../constants';
import { getMobileOperatingSystem } from '../../utils/common';
import * as classes from './style.module.scss';

const OS = getMobileOperatingSystem();

const allDerivedCreated = (assets) => assets.every((asset) => {
    switch (asset.resource_type) {
        case 'image': 
            if (CLOUDINARY_3D_TYPES.indexOf(asset.format) !== -1) {
                return DERIVED_ASSETS_URL_FACTORY.usdz(asset) && DERIVED_ASSETS_URL_FACTORY.glb(asset) && DERIVED_ASSETS_URL_FACTORY.modelThumbGif(asset);
            } else {
                return DERIVED_ASSETS_URL_FACTORY.image(asset);
            }
        case 'video': 
            return DERIVED_ASSETS_URL_FACTORY.video(asset) && DERIVED_ASSETS_URL_FACTORY.videoGif(asset);
        case 'raw': 
        default: 
            // We do not expect any transformation outputs for raw file inputs
            return true;
    }

});

const Assets = () => {
    const { folderId } = useParams();
    const [searchParams] = useSearchParams();
    const generated = searchParams.get('generated');
    const navigate = useNavigate();
    const [assets, setAssets] = useState([]);

    useEffect(() => {
        const getAssets = async () => {
            try {
                const assets = await api.bill.assets.getAssetsByFolderId(folderId);
                if (_.get(assets, 'length', 0) === 0) {
                    throw new Error('No Bill files found.');
                }
                setAssets(assets);
            } catch (error) {
                logger.error(`ERROR: Failed to fetch Bill assets: ${JSON.stringify(error)}`);
                navigate(`/error?message=${_.get(error, 'message').substring(0,100)}`);
                // navigate(`/error?message=${_.get(error, 'message')}`);
            }
        };
        getAssets();
    }, []);

    const [models, imagesAndVideos] = _.partition(assets, (asset) => CLOUDINARY_3D_TYPES.indexOf(asset.format) !== -1);
    const [images, videos] = _.partition(imagesAndVideos.filter((asset) => asset.resource_type !== 'raw'), (asset) => asset.resource_type === 'image');

    const assetsLoaded = assets && assets.length > 0;
    const assetsDerived = allDerivedCreated(assets);

    const minsSinceGenerated = (new Date().getTime() - Number(generated)) / 60000;

    return (
        <div className={classes.assets}>
            <div className={classes.contentContainer}>
                <Header type='assets' loaded={assetsLoaded}/>
                {!assetsLoaded && <div className={`${classes.smallHeaderText} ${classes.pulsingText}`}>{'// LOADING...'}</div>}
                {
                    assetsLoaded && !assetsDerived &&  
                    <>
                        {
                            minsSinceGenerated > 10 
                                ? <div className={`${classes.smallHeaderText}`}>{'// SOMETHING WENT WRONG WITH YOUR CUSTOM FILES. PLEASE SEE A BILL TECHNICIAN.'}</div>
                                : <div className={`${classes.smallHeaderText} ${classes.pulsingText}`}>{'// YOUR CUSTOM FILES ARE BEING GENERATED! PLEASE CHECK BACK IN 10 MINUTES.'}</div>
                        }
                    </>
                }
                {
                    assetsLoaded && assetsDerived && 
                    <>
                        {
                            (images.length > 0 || videos.length > 0) && <>
                                <div className={classes.smallHeaderText}>{'// RECAP'}</div>
                                <div className={classes.largeHeaderText}>YOUR PHOTOS <br/> AND VIDEOS</div>
                                {
                                    images.map((asset) => 
                                        <img key={asset.asset_id} 
                                            className={classes.assetsImage} 
                                            src={_.get(DERIVED_ASSETS_URL_FACTORY.image(asset), 'secure_url', '')} alt=''
                                        />)}
                                {
                                    videos.map((asset) => <VideoPlayer OS={OS} key={asset.asset_id} asset={asset}/>)  
                                }
                            </>
                        }
                        {
                            models.length > 0 && 
                            <>
                                <div className={classes.smallHeaderText}>{'// 3D'}</div>
                                {
                                    MOBILE_OS_FLAVORS_WITH_MODEL_SUPPORT.indexOf(OS) !== -1 ? 
                                        <div className={classes.largeHeaderText}>TAP TO VIEW <br/>IN MOBILE AR</div>
                                        :
                                        <div className={classes.largeHeaderText}>DOWNLOAD <br/> 3D MODELS</div>
                                }
                                {models.map((asset) => <ThreeDModel key={asset.asset_id} asset={asset}/>)}
                            </>
                        }
                    </>
                }
            </div>
        </div>
    );
};
export default Assets;