@import '../../../vars.scss';

@mixin swoosh {
    position: absolute;
    top: 30%;
    right: 3.8%;
}

.header {
    width: $masterWidth;    
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 2vh 0;

    .headerImage {
        width: $masterWidth
    }
    .robotImage {
        width: $masterWidth;
        position: relative;
        top: -1px;
        width: calc($masterWidth - 2px);
        border: 0.5px black solid;
    }

    .swoosh {
        @include swoosh;
    }



    .spinningSwoosh {
        @include swoosh;
        animation: spin 3s linear infinite;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
