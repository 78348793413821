[
    {
        "label": "Thursday, Sep 29",
        "url": "https://www.nike.com/gb/experiences/details/1661309876793"
    },
    {
        "label": "Friday, Sep 30",
        "url": "https://www.nike.com/gb/experiences/details/1661310248452"
    },
    {
        "label": "Saturday, Oct 1",
        "url": "https://www.nike.com/gb/experiences/details/1661310396619"
    },
    {
        "label": "Sunday, Oct 2",
        "url": "https://www.nike.com/gb/experiences/details/1661565763255"
    },
    {
        "label": "Monday, Oct 3",
        "url": "https://www.nike.com/gb/experiences/details/1661310642402"
    },
    {
        "label": "Tuesday, Oct 4",
        "url": "https://www.nike.com/gb/experiences/details/1661309816152"
    },
    {
        "label": "Thursday, Oct 6",
        "url": "https://www.nike.com/gb/experiences/details/1661309944551"
    },
    {
        "label": "Friday, Oct 7",
        "url": "https://www.nike.com/gb/experiences/details/1661310082276"
    }
]