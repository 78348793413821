@import '../../../vars.scss';

.scheduler {
    background: white;
    max-height: 45vh;
    margin-top: 2vh;
    width: 98%;
    position: relative;
    border: thin black solid;
    border-radius: 25px;
    overflow-x: hidden;
    overflow-y: scroll;

    
    .scheduleLink {
        border-bottom: thin black solid;
        height: 5.1vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 15px;
        color: black;
        text-decoration: none;
        &:hover {
            opacity: 0.5;
        }
    }
    
    :last-child {
        border-bottom: none;
    }
}

