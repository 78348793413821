@import '../../../vars.scss';

.login {
    font-family: 'Helvetica';
    height: 100%;
    width: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .submitButton {
        @include ovalButton;
        background: white;
        font-size: 0.8em;
        margin: 5px 0;
        padding: 5px 20px;
    }
}


