import React, { useState } from 'react';

import { Header, Scheduler } from '../../components';
import * as classes from './style.module.scss';

const Reserve = () => {
    const [schedulerOpen, setSchedulerOpen] = useState(false);
    

    // REMOVE FOR LAUNCH
    // const [authenticated, setAuthenticated] = useState(false);
    // if (!authenticated) {
    //     return (
    //         <div className={classes.reserve}>
    //             <div className={classes.contentContainer}>
    //                 <LoginModal
    //                     authenticated={authenticated}
    //                     setAuthenticated={setAuthenticated}
    //                 />
    //             </div>
    //         </div>
    //     );
    // }

    return (
        <div className={classes.reserve}>
            <div className={classes.contentContainer}>
                <Header type='reserve'/>
                <div className={classes.smallHeaderText}>{'// RESERVE'}</div>
                <div className={classes.largeHeaderText}>
                    RESERVE <br/>A SESSION
                    {/* <br/><span className={classes.subText}>All sessions currently reserved. Please check back soon for more appointments.</span> */}
                </div>
                {
                    !schedulerOpen && 
                    <button
                        className={ classes.stretchedOvalButton }
                        onClick={() => setSchedulerOpen(true)}
                    >
                        SELECT AN APPOINTMENT
                    </button>
                }
                {
                    schedulerOpen ? 
                        <div>
                            <Scheduler/>
                            {
                                schedulerOpen && 
                                    <button
                                        onClick={() => setSchedulerOpen(false)}
                                        className={classes.stretchedOvalButton}
                                    >
                                        CLOSE
                                    </button>
                            }

                        </div>
                        :
                        <div>
                            <div className={classes.smallHeaderText}>{'// ABOUT'}</div>
                            <div className={classes.largeHeaderText}>
                                I&apos;M A BOT
                                <br/>1NITIATED
                                <br/>LONGEVITY
                                <br/>LAB.
                                <br/>I&apos;M HERE TO
                                <br/>REPAIR YOUR
                                <br/>HARD-WORKING
                                <br/>SNKEAK3RS!
                            </div>
                        </div>
                        

                }
            </div>
        </div>
    );
};
export default Reserve;