@import '../../../vars.scss';

.assets {
    @include pageStyles;
    .contentContainer {
        .assetsImage {
            @include mediaContainer
        }
    }
}

