import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import api from '../../api';
import { isLocalDevelopment } from '../../utils/common';
import * as classes from './style.module.scss';

const LoginModal = ({ authenticated, setAuthenticated }) => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const handleSubmit = async () => {
        try {
            if (!isLocalDevelopment()) {
                await api.bill.auth.authPassword(password);
            }
            setAuthenticated(true);
        } catch (error) {
            setError('UNAUTHORIZED');
        }
    };

    useEffect(() => {
        const keyDownHandler = (event) => {    
            if (event.key === 'Enter') {
                event.preventDefault();
    
                // 👇️ call submit function here
                if (password) {
                    handleSubmit();
                }
            }
        };
    
        document.addEventListener('keydown', keyDownHandler);
    
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [password]);

    return (
        <Modal
            isOpen={!authenticated}
        >
            <div className={classes.login}>
                { error && <div className={classes.error}>{error}</div> }
                <div>PASSWORD:</div>
                <input type="text" value={password} onChange={(e) => setPassword(e.target.value) } />
                { password && <button className={classes.submitButton} onClick={handleSubmit} disabled={!password}>SUBMIT</button> }
            </div>
        </Modal>
    );
};

Modal.setAppElement('body');

LoginModal.propTypes = {
    authenticated: PropTypes.bool,
    setAuthenticated: PropTypes.func,
};

export default LoginModal;